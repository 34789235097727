import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import documenti from '../data/pratiche-patenti/rinnovo/documenti.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faEuroSign, faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from "gatsby-plugin-image"


export const FeatureScale = (props) => {
  return (
    <div className="relative">
      <StaticImage
        src="../images/island.jpeg"
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-opacity-70 bg-blue-800 px-4 py-16 md:px-24 lg:px-36 lg:py-20">
        <div className="lg:px-48 py-12 flex items-center sm:flex-row flex-col">
          <div
            className={`h-32 w-32 sm:mr-10 inline-flex items-center justify-center rounded-full bg-blue-900 text-white flex-shrink-0`}
          >
            <FontAwesomeIcon
              icon={faIdCard}
              className="pt-2 fa-solid flex-shrink-0 mx-4 h-16 w-16 text-white"
            />
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-3xl font-bold title-font mb-2">
              Documenti necessari
            </h2>
            <ul className="mt-2">
              {documenti.docList.map((el) => (
                <li id={el.id} className="leading-relaxed text-left text-lg mb-4 text-white">
                  {el.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="lg:px-48 py-12 flex items-center sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-3xl title-font font-medium mb-2">Prezzo</h2>
            <p className="leading-relaxed text-lg text-white">
              Compresi: bollettini, visita medica, fototessera, competenze autoscuola!
            </p>
          </div>
          <div className={`sm:order-none order-first h-32 w-32 sm:ml-4 inline-flex items-center justify-center rounded-full bg-blue-900 text-white flex-shrink-0`}>
            <p className="leading-relaxed font-bold text-4xl text-white">
              100
              <FontAwesomeIcon
                icon={faEuroSign}
                className="fa-solid flex-shrink-0 h-8 w-8 text-white"
              />
            </p>
          </div>
        </div>
        <div className="lg:px-48 py-12 flex items-center sm:flex-row flex-col">
          <div
            className={`h-32 w-32 sm:mr-10 inline-flex items-center justify-center rounded-full bg-blue-900 text-white flex-shrink-0`}
          >
            <FontAwesomeIcon
              icon={faStethoscope}
              className="pt-2 fa-solid flex-shrink-0 mx-4 h-16 w-16 text-white"
            />
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-white text-2xl title-font font-medium mb-2">
              Orari visite mediche
            </h2>
            <p className="leading-relaxed text-lg text-white">Ogni giovedì dalle 17:00s in poi</p>
            <Link href="/orari/#orario-visite-mediche" className={`mt-3 text-blue-300 inline-flex items-center`}>
              Scopri di più
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

FeatureScale.defaultProps = {
  theme: 'indigo',
};

FeatureScale.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default FeatureScale;
