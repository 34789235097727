import React from 'react';
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import { setPluginOptions } from 'gatsby-plugin-sharp/plugin-options';


export const Step = (props) => {

  const {
    id,
    steps
  } = props;

  return (




    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="grid gap-12 row-gap-10 lg:grid-cols-2">
        <div className="lg:py-6 lg:pr-16">
          {steps.map(s =>
            <div className="flex h-60">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-16 h-16 border-2 border-blue-accent-400 rounded-full">
                    <FontAwesomeIcon
                      icon={s.icon}
                      className="fa-solid flex-shrink-0 mx-4 w-8 h-8 text-blue-accent-400"
                    />
                  </div>
                </div>
                {s.type === "step" ? <div className="w-px h-full bg-blue-accent-400" /> : null}
              </div>
              <div className="mb-16">
                <p className="text-xl text-blue-900 font-sans font-semibold">{s.step}</p>
                <p className="text-xl text-blue-900 font-roboto font-semibold">
                  {s.description}
                  {s.link !== null ?
                    <Link href={s.link} className="cursor-pointer text-blue-accent-400">
                      <br />
                      <span className="mt-4 cursor-pointer text-blue-accent-400">
                        Scopri di più ➞
                      </span>
                    </Link>
                    : null}
                </p>


              </div>
            </div>
          )}



        </div>
        <div className="sm:mt-0 mt-16">
          <img
            className="object-cover h-full"
            src="/eye.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};