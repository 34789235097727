import { faBolt, faCar, faPen} from '@fortawesome/free-solid-svg-icons';

export const rinnovo = {
    brand: "Novità",
    title: "Rinnovare la patente non è mai stato così facile!",
    par1: "Affidati al nostro Rinnovo 2.0, veloce, semplice, economico",
    par2: "Segui gli step indicati qui sotto",
    icon1: faBolt,
    feature1: "Efasf",
    icon2: faCar,
    feature2: "Efasf",
    icon3: faPen,
    feature3: "Efasf",
}