import React from 'react';

const Table = (props) => {
  return (
    <div className="relative overflow-x-auto">
      <table className="mx-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 font-roboto tracking-tight">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" aria-label="Fasce" className="bg-white px-6 py-3 text-lg"></th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg">Fasce d'età</th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg"></th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg"></th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg"></th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg"></th>
            <th scope="col" aria-label="Fasce" className="bg-blue-900 text-white px-6 py-3 text-lg"></th>
          </tr>
          <tr>
            <th scope="col" className="bg-blue-900 text-white px-6 py-3 text-lg">
              Categoria Patente
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              18-50 anni
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              51-60 anni
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              61-65 anni
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              66-70 anni
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              71-80 anni
            </th>
            <th scope="col" className="bg-blue-accent-700 text-white px-6 py-3 text-lg">
              80+ anni
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="dark:bg-gray-800 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
            <th scope="row" className="bg-blue-accent-700 text-white text-lg px-6 py-4 font-medium dark:text-white whitespace-nowrap">
              A, B
            </th>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 10 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 3 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 2 anni</td>
          </tr>
          <tr className="dark:bg-gray-800 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
            <th
              scope="row"
              className="text-lg px-6 py-4 font-medium bg-blue-accent-700 text-white whitespace-nowrap"
            >
              A, B speciali
            </th>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 3 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 2 anni</td>
          </tr>
          <tr className="dark:bg-gray-800 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
            <th
              scope="row"
              className="text-lg px-6 py-4 font-medium bg-blue-accent-700 text-white whitespace-nowrap"
            >
              C <br />C speciale
            </th>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 3 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-white">Ogni 2 anni</td>
          </tr>
          <tr className="dark:bg-gray-800 oddtext-white bg-blue-accent-200 odd:dark:bg-gray-800 even:dark:bg-gray-700">
            <th
              scope="row"
              className="text-lg px-6 py-4 fotext-white bg-blue-accent-700 text-white whitespace-nowrap"
            >
              D<br />D speciale
            </th>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200">Ogni 5 anni</td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200"></td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200"></td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200"></td>
            <td className="px-6 py-4 text-lg text-blue-accent-400 bg-blue-200"></td>

          </tr>
          <tr className="dark:bg-gray-800 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
            <th
              scope="row"
              className="text-lg px-6 py-4 font-medium bg-blue-accent-700 text-white whitespace-nowrap"
            >
              E
            </th>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white">
              Rispetta le regole della patente ad essa associata
            </td>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white"></td>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white"></td>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white"></td>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white"></td>
            <td className="px-2 py-4 text-lg text-blue-accent-400 bg-white"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
