import { faIdCard, faEuroSign, faStethoscope, faE, faEur, faEuro } from '@fortawesome/free-solid-svg-icons';

export const documentiNecessari = {
    title: "Ricapitolando",
    subtitle: null,
    list: [
        {
            id: "pat1",
            title: "Porta con te la patente scaduta",
            subtitle: "o prossima alla scadenza",
            link: null,
            icon: faIdCard,
        },
        {
            id: "pat2",
            title: "Il costo del servizio è di 105 €",
            subtitle: "Compresi: bollettini, visita medica, fototessera, competenze autoscuola!",
            link: null,
            icon: faEuroSign,
        },
        {
            id: "pat3",
            title: "Orari visite mediche",
            subtitle:"Il medico sarà presente in autoscuola ogni mercoledì dalle ore 17",
            link:'/orari',
            icon: faStethoscope,
        }


    ],
    buttonText: null
}