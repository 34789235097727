import React from 'react';
import Layout from '../../components/layout/Layout';
import Table from '../../components/Table';
import FeatureScale from '../../components/FeatureScale';
import { Step } from '../../components/Step';
import { HeroPromoFeature } from '../../components/HeroPromoFeature';
import { rinnovo } from "../../data/pratiche-patenti/rinnovo/rinnovo";
import { rinnovoHighlight } from "../../data/pratiche-patenti/rinnovo/rinnovo_highlight";
import { steps } from '../../data/pratiche-patenti/rinnovo/step';
import { documentiNecessari } from '../../data/pratiche-patenti/rinnovo/feature';

import { FeatureIcons } from '../../components/FeatureIcons';
import PageNavigator from '../../components/layout/PageNavigator';
import { HeroHighlight } from '../../components/HeroHighlight';
import PageNavigatorWhite from '../../components/layout/PageNavigatorWhite';
import Seo from '../../components/layout/SEO';



const RinnovoPatente = () => {
  return (
    <Layout>
      <Seo
        title={"Rinnovo Veloce della Patente | Autoscuola Faro a Pisa"}
        description={"Ti scattiamo la foto ed acquisiamo la tua firma digitale per velocizzare il rinnovo della patente. Pensiamo a tutto noi!"}
        keywords={[
          "rinnovo patente Pisa",
          "patente Pisa",
          "rinnovo patente Autoscuola Pisa",
          "visita medica rinnovo patente Pisa"
        ]}
      />

      <PageNavigatorWhite
        link="/pratiche-patenti"
        text="Pratiche patenti"
        bgColor="blue-accent"
        bgIntensity="400"
        textColor="gray"
        textIntensity="100"
      />
      <HeroHighlight
        title={rinnovoHighlight.title}
        newLine={rinnovoHighlight.newLine}
        highlight={rinnovoHighlight.highlight}
        par1={rinnovoHighlight.par1}
        par2={rinnovoHighlight.par2}
        internalLink={rinnovoHighlight.internalLink}

      />
      {/* <HeroPromoFeature
        brand={rinnovo.brand}
        title={rinnovo.title}
        par1={rinnovo.par1}
        par2={rinnovo.par2}
      /> */}
      <Step id="steps" steps={steps} />
      <section className="py-12 ">
        <div className="lg:px-42 md:px-24 px-12 flex flex-col">
          <h1 className="text-center font-roboto font-semibold tracking-tighter sm:text-6xl text-4xl py-16 text-blue-accent-700">
            Consulta la tabella per scoprire la validità della tua patente
          </h1>
          <div>
            <Table />
          </div>
        </div>
      </section>
      <FeatureIcons title={documentiNecessari.title} subtitle={documentiNecessari.subtitle} list={documentiNecessari.list} />

    </Layout>
  );
};

export default RinnovoPatente;
