import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faEuroSign, faStethoscope } from '@fortawesome/free-solid-svg-icons';
import "../css/clip.css";

export const FeatureIcons = (props) => {

	const {
		title,
		subtitle,
		list
	} = props;

	return (

		<div className="relative clipped ">
			{/* <StaticImage
				src="../images/car_driving.jpg"
				className="absolute inset-0 object-cover w-full h-full"
				alt=""
			/> */}

			<div className="parallelogram bg-blue-accent-700 px-4 mx-auto md:px-16 lg:px-8 py-28 md:py-36">
				{/* <svg
					className="absolute w-full -mb-1 inset-x-0 bottom-0 text-white"
					viewBox="0 0 1160 163"
					preserveAspectRatio="none"
				>
					<path
						fill="currentColor"
						d="M -104 162 V 74 L 1269 163 V 163 Z M -115 -367 L -115 -298 L 540 372 L 1266 -181 L 1269 -362 L -115 -367"
					/>
				</svg> */}
				<div className="mb-4 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
					<h2 className="text-center mb-6 font-roboto text-5xl font-semibold leading-none tracking-tighter text-white sm:text-6xl">
						{title}
					</h2>
					<p className="text-base text-gray-700 md:text-lg">
						{subtitle}
					</p>
				</div>

				<div className="grid gap-8 row-gap-8 lg:grid-cols-3">
					{list.map((el) =>
						<div id={el.id} className="text-center">
							<div className="mx-auto flex flex-col items-center justify-center w-32 h-32 mb-4 rounded-full bg-teal-accent-400">
								<p className="leading-relaxed font-bold text-4xl text-white">
									<FontAwesomeIcon
										icon={el.icon}
										className="fa-solid mr-1 mt-2 h-12 w-12 text-teal-900"
									/>
								</p>
							</div>
							<h6 className="mb-2 font-sans font-semibold text-3xl leading-8 text-white">{el.title}</h6>
							<p className="mb-3 font-sans font-normal text-lg text-white sm:mx-auto">
								{el.subtitle}
							</p>
							{el.link !== null ?
								<a
									href={el.link}
									aria-label=""
									className="text-xl inline-flex items-center font-roboto font-semibold tracking-tight transition-colors duration-200 text-teal-accent-400 hover:text-teal-900"
								>
									Scopri di più
									<svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7" />
                  </svg>
								</a>
								: null}

						</div>
					)}


				</div>
				
			</div>
		</div>
	);
};