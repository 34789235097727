import {  faArrowDown, faCheck, faPlay} from '@fortawesome/free-solid-svg-icons';


export const steps = [
    {
        id: 1,
        step: "Vieni in autoscuola ",
        description: "Porta con te la patente scaduta o prossima alla scadenza",
        icon: faPlay,
        link: null,
        type:"step",
    },
    {
        id: 2,
        step: "Lascia che il nostro staff pensi alla foto e alla firma",
        description: "Sì, hai capito bene! Penseremo noi a scattarti la foto e ad acquisire la tua firma elettronica",
        icon: faArrowDown,
        link: null,
        type:"step",
    },
    {
        id: 3,
        step: "Mercoledì dalle 17 in poi",
        description: "Il medico è presente in autoscuola tutti i mercoledì dalle ore 17:00",
        icon: faArrowDown,
        link: "/orari",        
        type:"step",
    },
    {
        id: "Success",
        step: "La tua patente è rinnovata!",
        description: "Si ricorda che una volta effettuato il rinnovo, la nuova patente sarà disponibile in autoscuola a partire dai primi giorni della settimana successiva. Un nostro operatore provvederà ad avvisarti appena sarà arrivata",
        icon: faCheck,
        link: null,
        type:"check",
    },

]
